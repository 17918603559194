<template>
  <div class="application">
    <div class="application__content" v-loading="loading">
      <div>
        <div class="application__title">
          {{ $t('support.new_app.create_request') }}
        </div>

        <Selector
          ref="theme"
          class="application__selector application__input"
          :title="$t('support.new_app.request_topic')"
          :options="themes"
          :select-fitrst-item="false"
          @handleChange="(val) => form.helpdesk_theme_id = val"
        />
        <div
          class="form-input"
          :class="[{'form-input--full' : form.name},{'form-input--error' : errors.name}]"
        >
          <input v-model="form.name" type="text" required>
          <span class="form-input__label">{{$t('support.new_app.request_title')}}</span>
          <div
            v-if="errors.name"
            class="form-input__error"
            v-text="errors.name"
          />
        </div>
        <div
            class="form-input application__form-textarea"
            :class="
          [
            {'form-input--full' : form.message },
            {'form-input--error' : errors.message }
          ]"
        >
          <textarea v-model="form.message" class="application__message" />
          <span class="form-input__label">
            {{$t('support.new_app.request_message')}}
          </span>
          <div
            v-if="errors.message "
            class="form-input__error"
            v-text="errors.message "
          />
        </div>

        <div class="form-input">
          <div v-if="filesArr && filesArr.length" class="application__upload-files">
            <div v-for="(item, idx) in filesArr" :key="idx" class="application__upload-files-item">
              <span v-if="item.name" v-text="item.name" />
              <button class="application__upload-files-delete" @click="deleteFile(item.id)">
                <img src="@/assets/icons/icon-close.svg" alt="">
              </button>
            </div>
          </div>
          <label class="application__upload">
            <div class="button button--empty">{{$t('support.new_app.attach_file')}}</div>
            <input id="files" type="file" @change="checkFile">
          </label>
        </div>
      </div>
      

      <ButtonElement
        :text="$t('button_props.create_request')"
        :disabled="!form.name || !form.message || !form.helpdesk_theme_id"
        @click-button="createApplication"
        class="application__send-button"
      />
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/ButtonElement";
import formValidate from "@/mixin/formValidate";
import Selector from "@/components/sort/Selector";
import numberWithSpaces from '@/mixin/numberWithSpaces'

export default {
  name: 'NewApplication',
  components: {
    ButtonElement,
    Selector,
  },
  mixins: [formValidate, numberWithSpaces],
  data() {
    return {
      form: {
        name: '',
        message: '',
        helpdesk_theme_id: null,
      },
      errors: {
        name: '',
        message : '',
      },
      loading: false,

      filesArr: [],
    }
  },
  computed: {
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    themes() {
      return this.$store.state.Support.helpdeskThemes
    },
  },
  created() {
    this.loading = true
    this.$store.dispatch('Support/getHelpdeskThemes')
    .finally(() => {
      this.loading = false
    })
  },
  methods: {
    checkFile(e) {
      const files = e.target.files
      if (files && files.length) {
        let reader = new FileReader()
        files.forEach((el, idx) => {
          const fileType = el.type
          if (
              fileType === 'image/jpeg' ||
              fileType === 'image/jpg' ||
              fileType === 'image/png' ||
              fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
              fileType === 'application/pdf'
          ) {
            reader.onload = (e) => {
              this.filesArr.push({ id: idx + el.name, name: el.name, src: e.target.result, type: 'image', file: el })
            }
            reader.readAsDataURL(el)
          }
        })
      }
    },

    deleteFile(id) {
      this.filesArr = this.filesArr.filter(el => el.id !== id)
    },

    createApplication() {
      if (this.form.name && this.form.message && this.form.helpdesk_theme_id) {
        this.loading = true
        const data = new FormData()
        for (let key in this.form) {
          data.append(key, this.form[key])
        }
        data.append('business_account_id', this.activeAccount.id)

        if (this.filesArr && this.filesArr.length) {
          this.filesArr.forEach(el => {
            data.append('files[]', el.file)
          })
        }

        this.$store.dispatch('Support/createHelpdesk', data)
          .then(() => {
            this.cleanData()
            this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: null })
            this.$notify({
              message: this.$t('new_app.success'),
              type: 'success'
            });
          })
          .catch(() => {
            this.$notify({
              message: this.$t('new_app.error_message'),
              type: 'error'
            });
          })
          .finally(() => {
            this.loading = false
          })
      } 
    },
    cleanData() {
      this.$refs.theme.selectedValue = null
      for (let key in this.form) {
        this.form[key] = null
      }
      this.filesArr = []
    }
  }
}
</script>
<style lang="scss" scoped>
.application {
  &__message {
    font-family: 'Arial';
    min-height: 300px;
  }
}
</style>
<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.form-input--full .form-input__label {
    font-size: 1.2rem;
    line-height: 1.4rem;
    top: 0.6rem;
}

.application {
  background: #fff;
  color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__form-textarea  .form-input__label {
    width: calc(100% - 17px);
    background: white;
    z-index: 1;
    padding: 1.6rem 1.4rem 0.8rem;
    left: 1px;
    top: 1px;
  }

  &__send-button {
    margin-top: 2rem;
  }

  &__form-textarea textarea {
    padding-top: 4rem;
    scroll-padding-top: 3.8rem;
    overflow-y: scroll;
    resize: vertical;
  }

  &__form-textarea textarea:focus + .form-input__label {
    top: 1px;
    padding: 1.6rem 1.4rem 0.8rem;
  }

  &__message {
    font-family: 'Arial';
  }
  
  &__title {
    font-weight: 700;
    font-size: 4rem;
    line-height: 4.7rem;
    margin-bottom: 6.5rem;
    color: #000;
  }

  &__content {
    padding: 7rem;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .selector__content {
    background: none;
    border: 1px solid #BFC2C6;
  }

  .selector__title {
    font-size: 1.6rem;
    color: #878B93;
  }

  &__upload {
    input {
      display: none;
    }

    &-files {
      width: 100%;
      margin-bottom: 10px;

      &-item {
        padding: 1.4rem 0;
        border-bottom: 1px solid $gray7;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-child {
          border: none;
        }
      }

      &-delete {
        background: none;
        border: none;
      }
    }

    .button {
      font-weight: 400;
      color: var(--primary);
      background: #fff;
      font-size: 1.6rem;
      line-height: 1.9rem;
      border-radius: 4px;
      border: 1.5px solid var(--primary);
      min-width: 12.5rem;
      transition: 0.3s ease-out;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        color: #fff;
        background: var(--primary);
      }
    }
  }

  &__input {
    z-index: 99;
    
    .selector__content {
      width: 100%;
    }
  }

  .button {
    width: 100%;
    height: 5rem;
  }

  &__step {
    margin-bottom: 6rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-title {
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 2.4rem;
      color: #333333;
      margin-bottom: 2rem;
    }

    &-content {
      .form-input {
        width: 100%;
        max-width: 35rem;

        @include below($sm) {
          max-width: 100%;
        }
      }
    }

    &-error {
      color: $redLight;
      position: absolute;
      left: 6rem;
    }
  }
}

@include below(993px) {
  .application__title{
    font-size: 22px;
    line-height: 1.3;
    margin-bottom: 30px;
  }

  .application__step{
    margin-bottom: 30px;
  }

  .application__final,
  .application__content{
    padding: 15px;
  }
}

@include below(769px) {
  .application__final{
    .button{
      width: 100%;
      margin-top: 10px;
    }
  }
}
</style>
